import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PresentationModal from "../../organisms/PresentationModal";
import TextEdit from "../../molecules/TextEdit";
import SelectTextField from "../../molecules/SelectTextField";
import { useSignal } from "@preact/signals-react";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";

import { ErrorField } from '../../../types/ErrorField';
import { getErrorMessage } from '../../../utils/formErrorUtils';

const REASON_POST_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/reasons`;
const REASON_LIST_PAGE = "/referrals/reasons/0/100/description/ASC";

const ReasonFormModal = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const analysisType = searchParams.get('analysisType');

    if (!analysisType) {
        navigate("/not-found");
    }

    const [description, setDescription] = useState("");
    const [analysisAction, setAnalysisAction] = useState("APPROVE");

    const isLoadingForm = useSignal(false);
    const errorFields = useSignal<ErrorField[]>([]);

    const submitForm = async () => {
        isLoadingForm.value = true;
        errorFields.value = [];

        const token = await Auth.currentSession()
            .then((user) => user.getIdToken()
                .getJwtToken());

        const response = await fetch(REASON_POST_URL, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                description,
                analysisType,
                analysisAction: analysisAction
            })
        });

        if (response.ok) {
            toast.success("Cadastro realizado com sucesso!");
            navigate(`${REASON_LIST_PAGE}/d=${description};t=${analysisType};`);
        } else {
            const { message, errors } = await response.json();
            toast.error(message);
            errorFields.value = errors ? errors : [];
        }
        isLoadingForm.value = false;
    };

    return (
        <PresentationModal
            id="create-reason-modal"
            show={true}
            onClose={() => navigate(-1)}
            header="Novo motivo"
        >
            <div className="p-4">
                <div className="mb-4">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                        Descrição
                    </label>

                    <TextEdit type="text" onChange={(e) => setDescription(e.target.value)}
                        name='description'
                        label='Digite a descrição'
                        className='px-4 py-2'
                        value={description}
                        errorMessage={getErrorMessage(errorFields.value, 'description')}
                    />
                </div>

                <div className="mb-4">

                    <SelectTextField
                        id="analysis-type"
                        name="analysisType"
                        label="Selecione o tipo de motivo de análise"
                        value={analysisAction}
                        onChange={(e) => setAnalysisAction(e.target.value)}
                        items={[{
                            value: "APPROVE",
                            label: "Aprovação",
                        }, {
                            value: "REJECT",
                            label: "Rejeição",
                        }]}
                        errorMessage={getErrorMessage(errorFields.value, 'analysisType')}
                    />
                </div>

                <div className="flex justify-end">
                    <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="mr-2 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
                    >
                        Cancelar
                    </button>
                    <button
                        type="button"
                        onClick={submitForm}
                        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                    >
                        Salvar
                    </button>
                </div>
            </div>
        </PresentationModal>
    );
};

export default ReasonFormModal;
