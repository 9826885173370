import { useEffect, useRef } from 'react';
import PageForm from '../../templates/PageForm';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSignal } from '@preact/signals-react';
import CurrencyInput from '../../molecules/CurrencyInput';
import TextEdit from '../../molecules/TextEdit';
import useFetch from '../../../hooks/useFetch';
import { IoArrowRedo, IoArrowUndo } from 'react-icons/io5';
import { MdChecklistRtl, MdOutlineCheck } from 'react-icons/md';

import { Auth } from 'aws-amplify';

import ErrorMessage from '../../atoms/ErrorMessage';

import { ErrorField } from '../../../types/ErrorField';
import { concurrencyWithoutPrefix } from '../../../utils/currencyUtils';
import { getErrorMessage, hasErrorMessage, removeErrorField } from '../../../utils/formErrorUtils';
import DayPicker from '../../molecules/DayPicker';
import { currentDateAsString } from '../../../utils/DateUtils';

const ANALYSIS_TYPE = ['form-analysis-approve', 'form-analysis-reject', 'form-analysis-approve-reason', 'form-analysis-reject-reason'];
const FARMER_ANALYSIS_LIST_PAGE = "/referrals/farmer-analysis/0/100/referral.id/ASC";
const CREDIT_LIMIT_ANALYSIS_FORM_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/loan-order-analysis/form`;
const CREDIT_LIMIT_ANALYSIS_POST_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/loan-order-analysis`;

type FormMap = {
    pageTitle: string;
    reasonLabel: string;
}

const map: { [key: string]: FormMap } = {
    'form-analysis-approve': { pageTitle: 'Aprovação do pedido', reasonLabel: 'aprovação' },
    'form-analysis-reject': { pageTitle: 'Reprovação do pedido', reasonLabel: 'reprovação' },
    'form-analysis-approve-reason': { pageTitle: 'Aprovação (motivos) do pedido', reasonLabel: 'aprovação' },
    'form-analysis-reject-reason': { pageTitle: 'Reprovação (motivos) do pedido', reasonLabel: 'reprovação' }
};

interface Reason {
    id: string;
    description: string;
    total: number;
}

interface LoanOrderAnalysis {
    id: string;
    socialId: string;
    createdAt: string;
    farmerName: string;
    partnerGuarantorName: string
    partnerOriginName: string
    m0creditRuralLimit: number;
    m0creditRuralLimitFormatted: string;
    creditLimitApproved: number;
    creditLimitApprovedFormatted: string;
    requestedValue: number;
    requestedValueFormatted: string;
    reasons: Reason[];
    recommendationCode: string;
    recommendation: string;
    avalPercent: number;
    interestRate: number;
    expirationDate: string;
}

type Form = {
    referralId: string;
    analysisAction: string;
    approvedValue: number;
    avalPercent: number;
    interestRate: number;
    expirationDate: string;
    creditLimitAvailable: number;
    reasons: Reason[];
}


const LoanOrderAnalysisForm = () => {

    const navigate = useNavigate();
    const { referralId = "", analysisType = "" } = useParams();

    const inputRef = useRef<HTMLInputElement>(null);

    if (!referralId || !ANALYSIS_TYPE.includes(analysisType)) navigate("/not-found");

    const isLoadingForm = useSignal(false);

    const form = useSignal<Form>({
        referralId: referralId,
        analysisAction: analysisType.replace("form-", ""),
        approvedValue: 0,
        avalPercent: 0,
        interestRate: 0,
        expirationDate: currentDateAsString(),
        creditLimitAvailable: 0,
        reasons: []
    });

    const errorFields = useSignal<ErrorField[]>([]);
    const { isLoading, data, error, isTokenError } = useFetch(`${CREDIT_LIMIT_ANALYSIS_FORM_URL}/${analysisType.replace("form-", "")}/${referralId}`);

    const apiVersion = useRef('');
    const analysis = useSignal<LoanOrderAnalysis>({
        id: "",
        socialId: "",
        createdAt: "",
        farmerName: "",
        partnerGuarantorName: "",
        partnerOriginName: "",
        m0creditRuralLimit: 0,
        m0creditRuralLimitFormatted: "R$ 0,00",
        creditLimitApproved: 0,
        creditLimitApprovedFormatted: "R$ 0,00",
        requestedValue: 0,
        requestedValueFormatted: "R$ 0,00",
        reasons: [],
        recommendationCode: "",
        recommendation: "",
        avalPercent: 0,
        interestRate: 0,
        expirationDate: ""
    });

    const title = useSignal<string>("");

    if (isTokenError) {
        navigate("/denied");
    }

    if (error) {
        navigate("/network-error");
    }
    useEffect(() => {
        if (data) {
            apiVersion.current = `${data.apiName} - ${data.apiVersion}`;

            if (data.error) {
                navigate(-1);
                toast.error(data.messageError);
                return;
            }

            analysis.value = data.presenter;
            title.value = `${map[analysisType].pageTitle || 'Tipo desconhecido'} #${analysis.value.id}`;
            changeForm("approvedValue", analysis.value.requestedValue);
            changeForm("avalPercent", analysis.value.avalPercent);
            changeForm("interestRate", analysis.value.interestRate);
            changeForm("expirationDate", analysis.value.expirationDate);

            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [data]);

    const { reasonLabel } = map[analysisType];

    const changeForm = (field: string, value: any) => {
        if (field === "approvedValue") {

            form.value = {
                ...form.value,
                approvedValue: value,
                creditLimitAvailable: analysis.value.m0creditRuralLimit - value,
                //creditLimitAvailableFormatted: currencyWithPrefix(value),
            };

        } else {
            form.value = { ...form.value, [field]: value };
        }

        removeErrorField(errorFields.value, field);
    };

    const handleAddReason = (reason: Reason) => {

        if (!form.value.reasons.includes(reason)) {
            form.value = {
                ...form.value,
                reasons: [...form.value.reasons, reason]
            };
        }
    };

    const handleRemoveReason = (reason: Reason) => {
        form.value = {
            ...form.value,
            reasons: form.value.reasons.filter(r => r.id !== reason.id)
        };
    };

    const submitForm = async () => {
        isLoadingForm.value = true;

        const token = await Auth.currentSession()
            .then((user) => user.getIdToken()
                .getJwtToken());

        const [day, month, year] = form.value.expirationDate.split("/");
        const expirationDate = new Date(`${year}-${month}-${day}`).toISOString();

        const response = await fetch(CREDIT_LIMIT_ANALYSIS_POST_URL, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                loanOrderId: form.value.referralId,
                analysisAction: form.value.analysisAction,
                approvedValue: form.value.approvedValue,
                avalPercent: form.value.avalPercent,
                interestRate: form.value.interestRate,
                expirationDate,
                availableCreditLimit: form.value.creditLimitAvailable,
                reasonIds: form.value.reasons.map(reason => reason.id)
            })
        });

        if (response.ok) {
            toast.success(`${map[analysisType].pageTitle} realizada com sucesso!`);
            navigate(-1);
        } else {
            const { message, errors } = await response.json();
            toast.error(message);
            errorFields.value = errors || [];
        }
        isLoadingForm.value = false;
    };

    const cancelForm = () => {
        navigate(FARMER_ANALYSIS_LIST_PAGE);
    }

    return (
        <PageForm
            pageTitle={title.value}
            apiVersion={apiVersion.current}
            isLoading={isLoading || isLoadingForm.value}
            breadcrumb={[{ label: 'Pedidos dos produtores', url: FARMER_ANALYSIS_LIST_PAGE }, { label: title.value, url: '#' }]}
            controlSlot={(
                <div className='pt-4 flex justify-end space-x-2'>
                    {isLoading ? (
                        <div className="h-10 w-28 bg-gray-300 animate-pulse rounded" />
                    ) : (
                        <>
                            <button type='button'
                                className='text-black bg-gradient-to-r from-slate-200 via-slate-300 to-slate-400 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed'
                                onClick={cancelForm}
                                disabled={isLoadingForm.value || isLoadingForm.value}
                            >
                                <div className="flex items-center">
                                    Cancelar
                                </div>
                            </button>

                            {(analysisType === 'form-analysis-approve' || analysisType === 'form-analysis-approve-reason') && (
                                <button
                                    type='button'
                                    className={`text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed`}
                                    onClick={submitForm}
                                    disabled={isLoadingForm.value}
                                >
                                    Aprovar
                                </button>
                            )}

                            {(analysisType === 'form-analysis-reject' || analysisType === 'form-analysis-reject-reason') && (
                                <button
                                    type='button'
                                    className={`text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed`}
                                    onClick={submitForm}
                                    disabled={isLoadingForm.value}
                                >
                                    Reprovar
                                </button>
                            )}
                        </>
                    )}
                </div>
            )}
        >
            <div className="container mx-auto px-4 py-8 flex">
                {isLoading ? (
                    <div className="w-2/3 space-y-4">
                        <div className="h-4 bg-gray-300 rounded animate-pulse" />
                        <div className="h-4 bg-gray-300 rounded animate-pulse" />
                        <div className="h-8 bg-gray-300 rounded animate-pulse" />
                    </div>
                ) : (
                    <div className="w-2/3">
                        <div className="grid grid-cols-4 gap-4 mb-4">
                            <div className="col-span-1">
                                <TextEdit label="Data do pedido:" value={analysis.value.createdAt} disabled />
                            </div>
                            <div className="col-span-1">
                                <TextEdit label="CPF:" value={analysis.value.socialId} disabled />
                            </div>
                            <div className="col-span-2">
                                <TextEdit label="Nome do produtor:" value={analysis.value.farmerName} disabled />
                            </div>

                        </div>

                        <div className="mb-4">
                            <div className="col-span-2">
                                <TextEdit label="Parceira de origem:" value={analysis.value.partnerOriginName} disabled />
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="col-span-2">
                                <TextEdit label="Parceira avalista:" value={analysis.value.partnerGuarantorName} disabled />
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-4 mb-4">

                            <div className='col-span-3'>
                                <TextEdit prefix='R$' label="Valor solicitado:"
                                    value={concurrencyWithoutPrefix(analysis.value.requestedValue)}
                                    disabled
                                />
                            </div>

                            <div className='col-span-3'>
                                <TextEdit prefix='R$' label="Limite aprovado:"
                                    value={concurrencyWithoutPrefix(analysis.value.creditLimitApproved)}
                                    disabled
                                />
                            </div>

                            <div className='col-span-3'>
                                <TextEdit prefix='R$' label="L.C. disponível:"
                                    value={concurrencyWithoutPrefix(form.value.creditLimitAvailable)}
                                    disabled
                                />
                            </div>

                            <div className='col-span-3'>
                                {(analysisType === 'form-analysis-approve' || analysisType === 'form-analysis-approve-reason') ?
                                    <CurrencyInput
                                        label='Valor aprovado:'
                                        ref={inputRef}
                                        prefix='R$'
                                        value={form.value.approvedValue}
                                        onChange={(value) => changeForm("approvedValue", Number(value))}
                                        errorMessage={getErrorMessage(errorFields.value, "approvedValue")}
                                        disabled={isLoadingForm.value}
                                    />
                                    : null
                                }
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-4 mb-4">

                            <div className='col-span-4'>
                                <CurrencyInput label='% aval:'
                                    prefix='%'
                                    fractionDigits={2}
                                    value={form.value.avalPercent}
                                    onChange={(value) => changeForm("avalPercent", Number(value))}
                                    errorMessage={getErrorMessage(errorFields.value, "avalPercent")}
                                    disabled={isLoadingForm.value}
                                />
                            </div>

                            <div className='col-span-4'>
                                <CurrencyInput label='% de remuneração:'
                                    prefix='%'
                                    fractionDigits={2}
                                    value={form.value.interestRate}
                                    onChange={(value) => changeForm("interestRate", Number(value))}
                                    errorMessage={getErrorMessage(errorFields.value, "interestRate")}
                                    disabled={isLoadingForm.value}
                                />
                            </div>

                            <div className='col-span-4'>
                                <DayPicker
                                    value={form.value.expirationDate}
                                    displayFormat="DD/MM/YYYY"
                                    label='Data de vencimento:'
                                    onChange={(value) => changeForm("expirationDate", value)}
                                    errorMessage={getErrorMessage(errorFields.value, "expirationDate")}
                                />
                            </div>


                        </div>

                        {hasErrorMessage(errorFields.value, "referralId") ?
                            <ErrorMessage message={getErrorMessage(errorFields.value, "referralId")} />
                            : null}

                        <div id="reasons" className="mb-4 flex flex-col items-center justify-center max-w-full h-96 border-2 border-dashed rounded-lg bg-gray-50 hover:bg-gray-100">
                            {form.value.reasons.length === 0 && (
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <MdChecklistRtl className="text-4xl text-gray-500 " />
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        <span className="font-semibold">Motivo(s) selecionados para {reasonLabel}.</span>
                                    </p>
                                </div>
                            )}
                            <ul className="w-full flex-grow overflow-y-auto">
                                {form.value.reasons.map((reason) => (
                                    <li key={`selected-reason-${reason.id}`}
                                        onClick={() => handleRemoveReason(reason)}
                                        className="cursor-pointer p-2 border border-blue-300 rounded m-2 bg-white">
                                        <div className="flex items-center justify-between min-w-[450px]">
                                            <span>{reason.description}</span>
                                            <IoArrowRedo className="text-red-800 ml-2" />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}

                <div className="w-1/3 pl-4">
                    {isLoading ? (
                        <div className="space-y-4">
                            <div className="h-4 bg-gray-300 rounded animate-pulse" />
                            <div className="h-4 bg-gray-300 rounded animate-pulse" />
                            <div className="h-4 bg-gray-300 rounded animate-pulse" />
                        </div>
                    ) : (
                        <>
                            <h3 className='font-bold'>Motivos para {reasonLabel}</h3>
                            <ul className="border border-blue-300 p-2 rounded">
                                {analysis.value.reasons.map((reason) => (
                                    <li
                                        key={`reason-${reason.id}`}
                                        onClick={() => handleAddReason(reason)}
                                        className={`p-2 m-1 border border-blue-100 ${form.value.reasons.includes(reason) ? 'text-gray-400 bg-slate-50' : 'bg-white cursor-pointer hover:border-blue-500'}`}
                                    >
                                        <div className="grid grid-cols-10">
                                            {form.value.reasons.includes(reason) ?
                                                < MdOutlineCheck />
                                                : <IoArrowUndo className="col-span-1 text-lime-700" />}
                                            <span className='col-span-9'>
                                                {reason.description}
                                            </span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </div>
        </PageForm >
    );
};

export default LoanOrderAnalysisForm;
