import PageSearch from '../../molecules/PageSearch';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageList from '../../templates/PageList';
import { FaArrowRight, FaCheck } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { FaWhatsapp } from "react-icons/fa6";
import { MdMailOutline } from "react-icons/md";
import { extractAndConvertFrom } from '../../../utils/DateUtils';
import SelectFilter from '../../organisms/SelectFilter';
import DayPicker from '../../molecules/DayPicker';
import NewTooltip from '../../atoms/NewTooltip';
import { useSignal } from '@preact/signals-react';
import { toast } from "react-toastify";
import { TiEdit, TiTrash } from "react-icons/ti";

import { isDatePast } from '../../../utils/DateUtils';
import { useState } from 'react';

type Notification = {
    type_scheduled: string;
    operation: string;
    updatedAt: string;
    maxAttempts: number;
    attempts: number;
    media: string;
    status: string;
    createdAt: string;
    sentAt: string;
    contactName: string;
    phone: string;
    isPhoneValid: boolean;
    email: string;
    isEmailValid: boolean;
    canDispatch: boolean;
    canEdit: boolean;
    canDelete: boolean;
    errorMessage: string;
}

const DEFAULT_SCHEDULED_TYPE = 'WPP_DUPLICATE_BILLING_DUE_D-30';
const API_KEY = process.env.REACT_APP_COMMUNICATION_API_KEY || '';
const BASE_URL = process.env.REACT_APP_COMMUNICATION_API_URL;

const DEFAULT_STATUS_TO_CHECK_WPP = ['SENT', 'RECEIVED', 'DELIVERED'];

// TODO deve vir da api
export const DEFAULT_SCHEDULED_TYPES = [
    { value: 'WPP_DUPLICATE_BILLING_DUE_D-30', label: 'Cobrança duplicata 30 dias (whastapp)' },
    { value: 'MAIL_DUPLICATE_BILLING_DUE_D-30', label: 'Cobrança duplicata 30 dias (email)' },
];


const NotificationPageList = () => {
    const navigate = useNavigate();

    const { page, pageSize, field: fieldSorted, order: orderSorted, filter }: any = useParams();

    const isLoadingForm = useSignal<boolean>(false);

    const isEnableToNotify = useSignal<boolean>(false);
    const isEnableToCheckStatus = useSignal<boolean>(false);
    const isEnableToCreate = useSignal<boolean>(true);
    const countRowsToCheckStatus = useSignal<number>(0);

    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    let txtFullName = '';
    let txtScheduledType = '';
    let txtTargetDate = '';
    let ramdomTxt = '';

    const TODAY = new Date().toISOString().split('T')[0];

    if (filter) {
        [txtFullName = '', txtScheduledType, txtTargetDate, ramdomTxt] = filter.match(/=(.*?);/g)
            .map((match: string | any[]) => match.slice(1, -1));

        if (!txtScheduledType)
            txtScheduledType = DEFAULT_SCHEDULED_TYPE;

        if (!txtTargetDate)
            txtTargetDate = TODAY;
    }

    const url = `${BASE_URL}/notifications?fullName=${txtFullName}&notification_type=${txtScheduledType}&target_date=${txtTargetDate}&page=${page}&pageSize=${pageSize}&order=${fieldSorted},${orderSorted}`;

    const getCssColorBy = (status: string) => {

        const statusTable = {
            "PENDING": "bg-slate-400",
            "CHECKING": "bg-slate-500",
            "SENT": "bg-green-500",
            "ERROR": "bg-red-500",
            "RECEIVED": "bg-blue-500",
            "DELIVERED": "bg-blue-500",
            "CONSUMED": "bg-green-700",
            "FAILED": "bg-red-600",
        };

        return statusTable[status as keyof typeof statusTable] || "bg-gray-500";
    };

    const handleSelect = (selectedItem: string) => {
        setSelectedItems(selectedItems.includes(selectedItem) ?
            selectedItems.filter((item) => item !== selectedItem) :
            [...selectedItems, selectedItem]);
    };

    const selectAllItems = (checked: boolean, rows: Notification[]) => {
        if (checked) {
            setSelectedItems(rows
                .filter(row => row.canDispatch)
                .map((row: Notification) => row.operation));
        } else {
            setSelectedItems([]);
        }
    };

    const removeNotification = (operation: string) => {
        if (window.confirm('Deseja realmente remover essa notificação?')) {
            navigate(`/communication/notification/delete/${operation}`, { replace: true });
        }
    };

    const getContentByStatus = (status: string, errorMessage: string) => {
        const statusTable = {
            "PENDING": "Notificação pendente",
            "CHECKING": "Checando status da notificação",
            "SENT": "Notificação enviada",
            "ERROR": "Erro ao enviar notificação",
            "RECEIVED": "Notificação recebida",
            "DELIVERED": "Notificação entregue",
            "FAILED": "Falha ao enviar notificação:",
            "CONSUMED": "Visualizado pelo destinatário",
        };

        if (errorMessage)
            return (<div className="w-[300px]">
                <span className="font-bold text-red-500">Erro:</span> {errorMessage}
            </div>);

        return statusTable[status as keyof typeof statusTable] || "Status desconhecido";
    };

    const renderRows = (rows: Notification[]) => {

        isEnableToNotify.value = selectedItems.length > 0;
        isEnableToCreate.value = !isDatePast(txtTargetDate);

        countRowsToCheckStatus.value = rows.filter((row) => DEFAULT_STATUS_TO_CHECK_WPP.includes(row.status)).length;
        isEnableToCheckStatus.value = countRowsToCheckStatus.value > 0 && txtScheduledType === 'WPP_DUPLICATE_BILLING_DUE_D-30';

        if (!rows || rows.length === 0) {
            return (
                <tr>
                    <td colSpan={7} className="py-2 px-2 text-center">
                        <div className="text-2xl font-bold text-gray-500 mt-4">
                            Nenhuma notificação para esse dia...
                        </div>
                    </td>
                </tr>
            );
        }

        return rows.map((notification: Notification, index: number) => (
            <tr
                key={notification.operation}
                className={`group ${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border-gray-300`}
            >
                <td className="py-2 px-2">
                    {notification.canDispatch && (
                        <input
                            type="checkbox"
                            className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 focus:ring-2'
                            checked={selectedItems.includes(notification.operation)}
                            onChange={() => handleSelect(notification.operation)}
                        />
                    )}
                </td>
                <td className="py-2 px-2">
                    {notification.operation}
                </td>
                <td className="py-2 px-2">
                    {notification.contactName}
                </td>
                <td className="py-2 px-2 text-center" >
                    {notification.attempts} de {notification.maxAttempts}
                </td>
                <td>
                    {notification.media === "WHATSAPP" ? (
                        <span className='flex items-center'>
                            <NewTooltip content="Tipo de mídia">
                                <FaWhatsapp className='mr-3' />
                            </NewTooltip>
                            <NewTooltip content="Número do telefone">
                                {notification.phone}
                            </NewTooltip>
                            {notification.isPhoneValid ? (
                                <NewTooltip content="WhatsApp válido">
                                    <FaCheck className='ml-2 text-green-700' />
                                </NewTooltip>
                            ) : (
                                <NewTooltip content="WhatsApp inválido">
                                    <FcCancel className='ml-2' />
                                </NewTooltip>
                            )}
                        </span>
                    ) : (
                        <span className='flex items-center'>
                            <NewTooltip content="Tipo de mídia">
                                <MdMailOutline className='mr-3' />
                            </NewTooltip>
                            {notification.email}
                            {notification.isEmailValid ? (
                                <NewTooltip content="Email válido">
                                    <FaCheck className='ml-2 text-green-700' />
                                </NewTooltip>
                            ) : (
                                <NewTooltip content="Email inválido">
                                    <FcCancel className='ml-2' />
                                </NewTooltip>
                            )}
                        </span>
                    )}
                </td>
                <td>
                    {extractAndConvertFrom(notification.updatedAt)}
                </td>
                <td className="py-2 px-2" align="center">
                    <NewTooltip content={getContentByStatus(notification.status, notification.errorMessage)} alignment="left">
                        <span className={`rounded px-1 py-1 text-xs font-bold text-white ${getCssColorBy(notification.status)}`}>
                            {notification.status}
                        </span>
                    </NewTooltip>
                </td>
                <td>
                    {notification.status === 'PENDING' && (
                        <div className="opacity-0 group-hover:opacity-100 flex items-center transition-opacity duration-200">
                            {notification.canEdit && (
                                <NewTooltip content="Alterar dados" alignment="bottom">
                                    <Link to={`/communication/form/${notification.operation}`} className="text-blue-500 hover:text-blue-800">
                                        <TiEdit className='text-2xl' />
                                    </Link>
                                </NewTooltip>
                            )}

                            {notification.canDelete && (
                                <NewTooltip content="Remover notificação" alignment="left">
                                    <button onClick={() => removeNotification(notification.operation)}>
                                        <TiTrash className='text-2xl text-red-500 ml-1' />
                                    </button>
                                </NewTooltip>
                            )}
                        </div>
                    )}
                </td>
            </tr>
        ));
    };

    const pageChanged = (page: number) => {
        navigate(urlNavigate(page - 1, pageSize, fieldSorted, orderSorted, txtFullName, txtScheduledType, txtTargetDate), { replace: true });
    };

    const pageSizeChanged = (pageSize: number) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtFullName, txtScheduledType, txtTargetDate), { replace: true });
    };

    const pageSorted = (field: keyof Notification | string, order: 'ASC' | 'DESC') => {
        navigate(urlNavigate(page, pageSize, field, order, txtFullName, txtScheduledType, txtTargetDate), { replace: true });
    };

    const txtContactNameChanged = (description: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, description, txtScheduledType, txtTargetDate), { replace: true });
    };

    const onFilterClean = () => {
        const today = new Date().toISOString().split('T')[0];
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, '', DEFAULT_SCHEDULED_TYPE, today), { replace: true });
    };

    const urlNavigate = (page: number, pageSize: number, fieldSorted: string, orderSorted: string,
        fullName: string, scheduledType: string, targetDate: string) => {
        return `/communication/notification/${page}/${pageSize}/${fieldSorted}/${orderSorted}/f=${fullName};s=${scheduledType};d=${targetDate};`;
    };

    const filterScheduledTypeChange = (value: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtFullName, value, txtTargetDate), { replace: true });
    };

    const forceReload = () => {
        const txt = Math.random().toString(36).substring(7);
        navigate(`${urlNavigate(0, pageSize, fieldSorted, orderSorted, txtFullName, txtScheduledType, txtTargetDate)}txt=${txt};`, { replace: true });
    };

    const filterTargetDateChange = (value: string) => {
        value = value.split('/')
            .reverse()
            .join('-');
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtFullName, txtScheduledType, value), { replace: true });
    };

    const dispatch = async (service: string) => {
        isLoadingForm.value = true;
        let textMessage = "Notificação iniciada com sucesso!";
        if (service === 'WPP-STATUS') {
            textMessage = "Status das notificações checado com sucesso!";
        }
        try {

            const response = await fetch(`${BASE_URL}/dispatch`, {
                method: 'POST',
                headers: {
                    'x-api-key': API_KEY,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    notificationType: txtScheduledType,
                    targetDate: txtTargetDate,
                    service, //comportamento default: selecionar WPP ou MAIL pelo tipo de notificação
                    operations: selectedItems,
                }),
            });

            if (response.ok) {
                toast.success(textMessage);
                setSelectedItems([]);
                await new Promise(resolve => setTimeout(resolve, 5000));
                forceReload();
            } else {
                toast.error("Erro ao iniciar notificação!");
            }
        } catch (error) {
            toast.error("Ocorreu um erro na comunicação com o servidor!\nTente novamente mais tarde.");
        }
        isLoadingForm.value = false;
    };

    return (
        <PageList
            pageTitle={"Notificações do dia"}
            url={url}
            fetchHeaders={[
                { key: 'x-api-key', value: API_KEY },
            ]}
            breadcrumb={[{ label: 'Notificações do dia', url: "#" }]}
            tableFieldSort={fieldSorted}
            tableOrderSort={orderSorted}
            onTableSort={pageSorted}
            renderRows={renderRows}
            page={page}
            pageSize={pageSize}
            onPageChange={pageChanged}
            onItemsPerPageChange={pageSizeChanged}
            onTableSelect={selectAllItems}
            itemsLabel="notificações"
            defaultBackendHeader={false}
            tableHeaders={[{
                id: "column1",
                title: "Operação",
                width: "180px",
                class: 'text-center',
                field: 'operation',
                canSort: true,
            }, {
                id: "column2",
                title: 'Nome do contato',
                field: "contact.fullName",
                canSort: true,
            }, {
                id: "column3",
                title: 'Tentativas',
                width: "100px",
                field: "attempts",
                canSort: false,
            }, {
                id: "column4",
                title: 'Contato',
                field: "phoneNumber",
                width: "300px",
                canSort: false,
            },
            {
                id: "column5",
                title: 'Atualizado em',
                field: "updatedAt",
                width: "130px",
                canSort: false,
            }, {
                id: "column6",
                title: 'Status',
                field: "status",
                width: "100px",
                canSort: false,
            }, {
                "id": "column7",
                "title": "Ações",
                "width": "60px",
                "class": "text-center",
                "canSort": false,
            }]}>
            {{
                filterSlot: (
                    <div className="grid grid-cols-12 gap-1 h-[46px]">
                        <SelectFilter
                            id="slc-scheduled-type"
                            label="Tipo de notificação ..."
                            value={txtScheduledType}
                            onChange={filterScheduledTypeChange}
                            defaultVoidOption={false}
                            options={DEFAULT_SCHEDULED_TYPES}
                            className='col-span-5'
                        />

                        <DayPicker label="Agendado para..."
                            value={txtTargetDate.split('-').reverse().join('/')}
                            onChange={filterTargetDateChange}
                            className='col-span-2'
                        />

                        <PageSearch
                            value={txtFullName}
                            label="Procurar por..."
                            onSearch={txtContactNameChanged}
                            filterCleanVisible={true}
                            filterCleanEnabled={Boolean(txtFullName)}
                            onFilterClean={onFilterClean}
                            configureVisible={false}
                            className='col-span-5 h-[42px]'
                        />
                    </div>
                ),
                controlSlot: (
                    <div className='pt-2' >
                        {isEnableToNotify.value && (
                            <NewTooltip content="Clique aqui para disparar as notificações deste dia" alignment="left">
                                <button
                                    disabled={isLoadingForm.value}
                                    className='flex items-center text-white bg-[#FF9119] hover:bg-[#FF9119]/80 font-medium rounded text-sm px-5 py-2 text-center inline-flex mr-2'
                                    onClick={() => dispatch('')}
                                >
                                    <span className='inline-flex items-center justify-center w-6 h-4 me-2 text-xs font-semibold text-amber-800 bg-orange-200 rounded-full'>
                                        {selectedItems.length}
                                    </span>
                                    Notificar
                                    <FaArrowRight className="ml-2" />
                                </button>
                            </NewTooltip>
                        )}
                        {isEnableToCheckStatus.value && (
                            <NewTooltip content="Clique aqui para checar status das notificações deste dia" alignment="left">
                                <button
                                    title={ramdomTxt}
                                    disabled={isLoadingForm.value}
                                    className='text-white bg-green-500 hover:bg-green-700 font-medium rounded text-sm px-5 py-2 text-center mr-2'
                                    onClick={() => dispatch('WPP-STATUS')}
                                >
                                    <span className='inline-flex items-center justify-center w-6 h-4 me-2 text-xs font-semibold text-lime-50 bg-green-800 rounded-full'>
                                        {countRowsToCheckStatus.value}
                                    </span>
                                    Checar status
                                </button>
                            </NewTooltip>
                        )
                        }
                        {isEnableToCreate.value && (
                            <Link to={`/communication/batch-form/${txtScheduledType}/${txtTargetDate}`}
                                className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center">
                                Nova notificação
                            </Link>
                        )}
                    </div>
                ),
            }}
        </PageList >
    );
};

export default NotificationPageList;